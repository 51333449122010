import { useDispatch } from "react-redux";
import { hideModalPage, showModalPage } from "../../../../../../actions/uiActions";
import { css } from "emotion";
import { ClearIcon, CheckIcon } from "mdi-react";
import React from "react";
import EventsSelectionOverview from "./EventsSelectionOverview";
import ActionWrapper from "../../../../../ui/ActionWrapper";
import useSWR from "swr";
import { swrFetcher } from "../../../../../../utilities/swr-fetcher";

const EventSelector = ({ onChangeHandler, page, isCreateMode }) => {
  const dispatch = useDispatch();

  let eventIds = page?.meta?.eventIds ?? [];

  useSWR(!page?.meta?.eventIds ? `/admin/events?eventGroupId=${page?.meta?.eventGroupId}` : null, swrFetcher, {
    onSuccess: (events) => {
      if (!isCreateMode) {
        onChangeHandler({ target: { name: "meta", value: { ...page?.meta, eventIds: events.map((event) => event.id) } } });
      }
    },
  });

  const clearSelectedEvents = () => {
    onChangeHandler({ target: { name: "meta", value: { ...page?.meta, eventIds: [] } } });
  };

  const viewEventsSelections = () => {
    dispatch(
      showModalPage({
        title: "Vælg begivenheder",
        content: (
          <EventsSelectionOverview
            eventIds={page?.meta.eventIds}
            onChangeHandler={(newEventIds) => (eventIds = newEventIds)}
          />
        ),
        actionRight: (
          <ActionWrapper
            style={{ marginRight: "0.5rem" }}
            onClick={() => {
              onChangeHandler({ target: { name: "meta", value: { ...page?.meta, eventIds } } });
              dispatch(hideModalPage());
            }}
          >
            <CheckIcon />
          </ActionWrapper>
        ),
        useScrollView: true,
      })
    );
  };

  const formatEventMessage = (eventIds) => {
    const ids = Array.isArray(eventIds) ? eventIds.length : 0;

    if (ids === 0) return "Ingen begivenheder valgt";
    if (ids === 1) return "1 begivenhed valgt";
    return `${ids} begivenheder valgt`;
  };

  return (
    <div className={componentStyle()}>
      <button onClick={viewEventsSelections}>
        <span>{formatEventMessage(page?.meta?.eventIds)}</span>
      </button>
      <ClearIcon onClick={clearSelectedEvents} />
    </div>
  );
};

const componentStyle = () => css`
  width: 100%;
  border: 1px solid var(--midGrey);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 2.5rem;

  &:hover {
    background-color: var(--ultraLightGrey);
  }

  button {
    width: 100%;
    text-align: start;
    height: 100%;
    padding: 0 0.75rem 0 1rem;
    font-size: 1rem;
    color: var(--darkGrey);
    background-color: inherit;
    border-radius: 3px;
    cursor: inherit;
    border: none;

    &:hover {
      background-color: inherit;
    }
  }

  svg {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    cursor: inherit;

    &:active {
      border: 1px solid var(--ultraLightGrey);
    }
  }
`;

export default EventSelector;
