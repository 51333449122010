import { CHAT, CUSTOM_GROUP, EXTERNAL_LINK, NEWS_MODULES, REGISTRATION_MODULES } from "../../../../config/moduleTypes";

export const defaultCheck = ({ page, applicationLanguages }) => {
  const { title, title_fl, module } = page;

  if (!title_fl && !!applicationLanguages.foreign) return true;

  return !title || !module;
};

export const customGroupCheck = (page) => {
  const { module, meta } = page;

  return [CUSTOM_GROUP].includes(module) && !meta?.customGroupId;
};

export const newsCheck = (page) => {
  const { module, meta } = page;

  return NEWS_MODULES.includes(module) && ((!meta?.subTypeId && !meta?.name) || !meta?.newsPostsSortOrder);
};

export const externalLinkCheck = (page) => {
  const { module, meta } = page;

  return [EXTERNAL_LINK].includes(module) && !meta?.URL;
};

export const registrationsCheck = (page) => {
  const { module, meta } = page;

  return REGISTRATION_MODULES.includes(module) && !meta?.registrationId;
};

export const chatCheck = (page) => {
  const { module, targetGroup } = page;
  const { users, userGroups, masterGroups, customGroups, jobTitles } = targetGroup;

  return (
    [CHAT].includes(module) &&
    !users.length &&
    !userGroups.length &&
    !masterGroups.length &&
    !customGroups.length &&
    !jobTitles.length
  );
};
