import { swrFetcher } from "../../../../../../utilities/swr-fetcher";
import ScrollView from "../../../../../ui/ScrollView";
import useSWR from "swr";
import React, { memo, useState } from "react";
import EventListItem from "../../../../../event/EventListItem";
import pageNavigatorV2 from "../../../../../../utilities/page-navigator-v2";
import breakpoints from "../../../../../../config/breakpoints";
import { css } from "emotion";
import { CheckboxBlankCircleOutlineIcon, CheckboxMarkedCircleIcon } from "mdi-react";
import { useSelector } from "react-redux";

const EventsSelectionOverview = ({ eventIds, onChangeHandler }) => {
  const { primaryColor } = useSelector((state) => state.appConfig);

  const [selectedEventIds, setSelectedEventIds] = useState(eventIds ? eventIds : []);

  const { data: events, isValidating: loading } = useSWR("admin/events?format=standard", swrFetcher);

  const itEventChecked = (eventIds) => {
    if (selectedEventIds.includes(eventIds)) {
      return <CheckboxMarkedCircleIcon className="check-icon" />;
    } else {
      return <CheckboxBlankCircleOutlineIcon className="check-icon" />;
    }
  };

  const onClickHandler = (eventId) => {
    if (selectedEventIds.includes(eventId)) {
      setSelectedEventIds((eventIds) => {
        const ids = eventIds.filter((id) => id !== eventId);
        onChangeHandler(ids);
        return ids;
      });
    } else {
      setSelectedEventIds((eventIds) => {
        let ids = [...eventIds, eventId];
        onChangeHandler(ids);
        return ids;
      });
    }
  };

  return (
    <div className={componentStyle(primaryColor)}>
      {Array.isArray(events) &&
        events.map((event) => (
          <EventListItem
            hideAttendingIndicator={true}
            key={`event-overview-all-${event.id}`}
            onClick={() => onClickHandler(event.id)}
            rightIcon={itEventChecked(event.id)}
            isToggleable={true}
            event={event}
            maxWidth={breakpoints.lg}
            clickable={true}
          />
        ))}
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  margin: 1rem 0;

  svg.check-icon {
    fill: ${primaryColor};
    margin-right: 0.5rem;
  }
`;

export default memo(EventsSelectionOverview);
