import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import useSWR from "swr";
import { swrFetcher } from "../../utilities/swr-fetcher";

// Config
import { feedTypes } from "./config";
import notificationErrorTypes from "../../config/notificationErrorTypes";

// Redux actions
import { showContextMenu } from "../../actions/uiActions";

// Utilities
import pageNavigatorV2 from "../../utilities/page-navigator-v2";
import swrRequest from "../../utilities/swr-request";

// Components
import RegistrationPost from "./components/RegistrationPost";
import ActionWrapper from "../ui/ActionWrapper";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import { InformationOutlineIcon, ArrowForwardIcon, MoreVertIcon } from "mdi-react";
import ScrollView from "../ui/ScrollView";
import getLinkPathFromPageObject from "../../utilities/get-link-path-from-page-object";
import useCurrentPage from "../../hooks/useCurrentPage";
import NotificationNotFound from "../ui/NotificationNotFound";

function RegistrationSinglePost(props) {
  // page setup
  const dispatch = useDispatch();

  const { match } = props;

  const { language: lang } = useSelector((state) => state.language);

  const page = useCurrentPage({ pageId: match.params.pageId });

  if (Object.keys(page).length === 0) {
    page.title = "Title";
    page.lastVisitDate = null;
  }

  let URL = `registration/${page.dataId}/posts/${match.params.postId}`;

  const { data: post, error: error, loading } = swrRequest({ url: URL });

  function goToPage() {
    pageNavigatorV2({ path: getLinkPathFromPageObject(page, "absolute"), direction: "forward", pageId: page.id });
  }

  return (
    <Page>
      <TopBar
        useDefaultBackButton={true}
        title={page.title}
        actionRight={
          <ActionWrapper
            onClick={() =>
              dispatch(
                showContextMenu([
                  {
                    title: `Gå til ${page.title}`,
                    icon: <ArrowForwardIcon />,
                    callback: goToPage,
                  },
                ])
              )
            }
          >
            <MoreVertIcon />
          </ActionWrapper>
        }
      />

      <ScrollView style={{ paddingTop: "1rem" }}>
        {(!page.dataId || error) && !loading && (
          <NotificationNotFound error={error} type={notificationErrorTypes.postNotFound} lang={lang} />
        )}
        {page.dataId && !error && !loading && (
          <>
            <div style={{ height: "1rem" }} />

            {/* Content */}
            <RegistrationPost
              key={`registration-single-post__${post.id}`}
              feedType={feedTypes.all}
              post={post}
              page={page}
            />
          </>
        )}

        {/* Loading */}
        {loading && !error && <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.posts}...`} />}

        {!loading && !error && (
          <StatusBox
            style={{ marginTop: "3rem" }}
            icon={<InformationOutlineIcon />}
            title={lang.thisIsASearchResult}
            content={lang.thePostIsFrom__placeholder__.replace(/{{placeholder}}/gi, page.title)}
            action={{
              title: lang.visit__placeholder__.replace(/{{placeholder}}/gi, page.title),
              callback: goToPage,
            }}
          />
        )}
      </ScrollView>
    </Page>
  );
}

export default RegistrationSinglePost;
