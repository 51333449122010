/**
 * These functions are used to filter the selection tabs (the left side panel).
 */
export const filterFunctions = {
  users: ({ regex, data }) =>
    data.filter((d) => {
      if (d.name?.match(regex)) return true;
      if (d.jobTitle?.match(regex)) return true;
      if (d.userGroup?.title?.match(regex)) return true;
      if (d.masterGroup?.title?.match(regex)) return true;
      return false;
    }),
  userGroups: ({ regex, data }) =>
    data.filter((d) => {
      if (d.title?.match(regex)) return true;
      if (d.masterGroup?.title?.match(regex)) return true;
      return false;
    }),
  masterGroups: ({ regex, data }) =>
    data.filter((d) => {
      if (d.title?.match(regex)) return true;
      if (d.masterGroup?.title?.match(regex)) return true;
      return false;
    }),
  customGroups: ({ regex, data }) =>
    data.filter((d) => {
      if (d.title?.match(regex)) return true;
      if (d.customGroup?.title?.match(regex)) return true;
      return false;
    }),
  jobTitles: ({ regex, data }) =>
    data.filter((d) => {
      if (d.title?.match(regex)) return true;
      return false;
    }),
};
