export default [
  { id: "+1", title: "🇺🇸+1" }, // USA
  { id: "+32", title: "🇧🇪+32" }, // Belgien
  { id: "+33", title: "🇫🇷+33" }, // France
  { id: "+34", title: "🇪🇸+34" }, // Spain
  { id: "+39", title: "🇮🇹+39" }, // Italy
  { id: "+40", title: "🇷🇴+40" }, // Romania
  { id: "+44", title: "🇬🇧+44" }, // United Kingdom
  { id: "+45", title: "🇩🇰+45" }, // Denmark
  { id: "+46", title: "🇸🇪+46" }, // Sweden
  { id: "+47", title: "🇳🇴+47" }, // Norway
  { id: "+48", title: "🇵🇱+48" }, // Poland
  { id: "+49", title: "🇩🇪+49" }, // Germany
  { id: "+299", title: "🇬🇱+299" }, // Greenland
  { id: "+351", title: "🇵🇹+351" }, // Portugal
  { id: "+353", title: "🇮🇪+353" }, // Ireland
  { id: "+358", title: "🇫🇮+358" }, // Finland
  { id: "+359", title: "🇧🇬+359" }, // Bulgaria
  { id: "+370", title: "🇱🇹+370" }, // Lithuania
  { id: "+420", title: "🇨🇿+420" }, // Czech Republic
  { id: "+886", title: "🇹🇼+886" }, // Taiwan,
  { id: "+298", title: "🇫🇴+298" }, // Faroe
];
