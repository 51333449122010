import React, { useState } from "react";
import RadioGroup from "../../../../ui/RadioGroup";
import useSWR from "swr";
import { swrFetcher } from "../../../../../utilities/swr-fetcher";
import DropDown from "../../../../ui/dropDown/components/DropDown";
import EventSelector from "./ui/EventSelector";
import { useSelector } from "react-redux";

const EventContent = (props) => {
  const { page, onChangeHandler, isCreateMode = false } = props;

  const lang = useSelector((state) => state.language.language);
  const [showAllEvents, setShowAllEvents] = useState(page?.meta?.eventGroupId ? false : true);

  const onShowAllEventsChangeHandler = () => {
    setShowAllEvents(!showAllEvents);
    onChangeHandler({ target: { name: "meta", value: { ...page?.meta, showAllEvents: !showAllEvents } } });
  };

  return (
    <>
      <div className="line" />
      <div className="field-container">
        <p className="title">{lang?.eventsContent}</p>

        <div className="field-wrapper">
          <label>{`${lang?.showAllEvents}?`}</label>
          <RadioGroup
            data-test-id={"event-content-radio-group"}
            value={showAllEvents ? 1 : 2}
            onSelect={onShowAllEventsChangeHandler}
            className="show-at-front-page-group"
            selectedValue={showAllEvents ? 1 : 2}
            options={[
              { id: 1, title: lang?.yes, value: true },
              { id: 2, title: lang?.no, value: false },
            ]}
          />
        </div>

        {!showAllEvents && (
          <div className="field-wrapper">
            <label>{`${lang?.choose} ${lang?.events?.toLowerCase() ?? ""}`}</label>
            <EventSelector isCreateMode={isCreateMode} page={page} onChangeHandler={onChangeHandler} />
          </div>
        )}
      </div>
    </>
  );
};

export default EventContent;
