// Libs
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import PageBuilderHeader from "./components/PageBuilderHeader";
import PageBuilderContent from "./components/PageBuilderContent";

// Hooks
import useSubPages from "./hooks/useSubPages";
import useCurrentPageBuilderPage from "./hooks/useCurrentPageBuilderPage";
import useApplicationLanguages from "../../hooks/useApplicationLanguages";

// Actions
import { getPageBuilderPages, updateParentPageId } from "../../actions/pageBuilderActions";

// Context
import { PageBuilderContext } from "./context/PageBuilderContext";
import shouldDraggableActionBeDisabled from "./utilities/shouldDraggableActionBeDisabled";
import ActionWrapper from "../ui/ActionWrapper";
import { ArrowLeftIcon } from "mdi-react";
import pageNavigatorV2 from "../../utilities/page-navigator-v2";
import getAppName from "../../utilities/get-app-name";

const PageBuilderSubPage = (props) => {
  const dispatch = useDispatch();

  const { applicationLanguages } = useApplicationLanguages();

  const { pages } = useSelector((state) => state.pageBuilder);

  const pageId = props.match.params.pageId;
  const parentPageId = props.match.params.parentPageId ?? undefined;

  const { page: parentPage } = useCurrentPageBuilderPage({ pageId: parentPageId });
  const { pages: subPages } = useSubPages({ parentPageId: parentPageId ? parseInt(parentPageId, 10) : undefined });

  useEffect(() => {
    dispatch(updateParentPageId({ parentPageId }));

    if (pages && pages.length === 0) dispatch(getPageBuilderPages());
  }, []);

  const disableDraggableAction = useMemo(() => {
    return shouldDraggableActionBeDisabled(parentPage);
  }, [parentPage]);

  const navigateBack = () => {
    const parentId = parentPage?.parentId ?? undefined;

    if (parentId) {
      pageNavigatorV2({ path: `/${getAppName()}/page-builder/${pageId}/parentPages/${parentId}`, direction: "backward" });
    } else {
      pageNavigatorV2({ path: `/${getAppName()}/page-builder/${pageId}`, direction: "backward" });
    }
  };

  return (
    <Page>
      <PageBuilderContext.Provider value={{ applicationLanguages, disableDraggableAction }}>
        <TopBar
          actionLeft={
            <ActionWrapper onClick={navigateBack}>
              <ArrowLeftIcon />
            </ActionWrapper>
          }
          title={parentPage ? parentPage.title : ""}
        />
        <PageBuilderHeader {...props} currentPage={parentPage} />
        <PageBuilderContent {...props} pages={subPages} />
      </PageBuilderContext.Provider>
    </Page>
  );
};

export default PageBuilderSubPage;
