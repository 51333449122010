import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useSelector } from "react-redux";
import { css } from "emotion";
import PropTypes from "prop-types";

// Components
import UserProfile from "../profile/UserProfile";
import Author from "./Author";

// Utilities
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";
import req from "../../utilities/request-utility";

// Style & Icons
import colors from "../../style/colors";
import { AlertCircleOutlineIcon } from "mdi-react";

// Actions
import { showModalPage, updateModalPage } from "../../actions/uiActions";

const PostLikes = (props) => {
  const { showModalPage, updateModalPage, likes = [] } = props;
  const lang = useSelector((state) => state.language.language);

  async function showUserModal(userId) {
    if (!userId) return;
    showModalPage({ content: <UserProfile userDataLoading={true} userDataError={false} /> });
    let { data: userData } = await req()(`users/${userId}`);
    updateModalPage({
      content: <UserProfile userDataLoading={false} userDataError={false} userData={userData} />,
    });
  }

  return (
    <div className={style(props)}>
      {likes.length === 0 && (
        <p className="meta">
          <AlertCircleOutlineIcon /> {lang.noLikes}
        </p>
      )}
      {likes.map((user) => (
        <Author
          key={`post-like__${user ? user.id : "..."}`}
          onClick={() => (user ? showUserModal(user.id) : null)}
          user={user}
          profilePictureSize={34}
        />
      ))}
    </div>
  );
};

const style = (props) => css`
  margin-left: -${props.boxPadding}rem;
  margin-right: -${props.boxPadding}rem;
  padding: ${props.boxPadding}rem ${props.boxPadding}rem ${props.boxPadding}rem ${props.boxPadding}rem;
  max-height: 50vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-top: 1px var(--lightGrey) solid;
  background-color: var(--white);

  .meta {
    /* If updating this make sure to also update the one in PostComments to match */
    color: var(--darkGrey);

    svg {
      width: 0.9rem;
      height: 0.9rem;
      color: var(--darkGrey);
      vertical-align: bottom;
      margin-bottom: 2px;
    }
  }

  .like {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    color: var(--darkGrey);
    margin-bottom: 0.75rem;

    &:last-of-type {
      margin-bottom: -${props.boxPadding}rem;
    }

    svg {
      color: var(--darkGrey);
      /* margin-right: 0.25rem; */
    }

    p {
      color: var(--black);
      font-weight: 400;
      font-size: 0.85rem;

      &.name {
        font-weight: 700;
      }

      &.user-group {
        color: var(--darkGrey);
      }
    }
  }
`;
PostLikes.propTypes = {
  /** Array which holds likes to be displayed by component */
  likes: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => ({
  showModalPage: bindActionCreators(showModalPage, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(PostLikes);
