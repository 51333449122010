import React from "react";

import {
  chatCheck,
  customGroupCheck,
  defaultCheck,
  externalLinkCheck,
  newsCheck,
  registrationsCheck,
} from "./useDisableCreatePageButton.utils";
import useApplicationLanguages from "../../../../../../hooks/useApplicationLanguages";

const UseDisableCreatePageButton = () => {
  const { applicationLanguages } = useApplicationLanguages();

  const disableButton = (page) => {
    return (
      defaultCheck({ page, applicationLanguages }) ||
      newsCheck(page) ||
      externalLinkCheck(page) ||
      registrationsCheck(page) ||
      chatCheck(page) ||
      customGroupCheck(page)
    );
  };

  return {
    disableButton,
  };
};

export default UseDisableCreatePageButton;
