// Libs
import React, { Fragment } from "react";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { v4 as uuidv4 } from "uuid";

// Components
import ContextMenuButton from "../ui/ContextMenuButton";
import Button from "../ui/Button";
import StatusBox from "../ui/StatusBox";
import EventFormBuilder from "./EventFormBuilder";
import Spinner from "../ui/InlineSpinner";
import EventAnswers from "./EventAnswers";

// Styles
import {
  AccountAlertIcon,
  AccountCheckIcon,
  AccountMultipleIcon,
  AccountOffIcon,
  CalendarBlankIcon,
  ClipboardTextOutlineIcon,
  HourglassEmptyIcon,
  CheckIcon,
  TrashCanOutlineIcon,
  CalendarPlusIcon,
  MapMarkerIcon,
  OpenInNewIcon,
} from "mdi-react";
import colors from "../../style/colors";
import ImageCarousel from "../ui/ImageCarousel";
import FileViewer from "../ui/FileViewer";
import ButtonRounded from "../ui/ButtonRounded";

// Config and utilities
import breakpoints from "../../config/breakpoints";
import markDownParser from "../../utilities/markdown-parser";
import { attendingType } from "./config";
import { parseDate, parseDateAndTime } from "../../utilities/parse-date";
import shouldShowEventButtons from "./utilities/shouldShowEventButtons";

// Actions
import { showContextMenu, showModalPage } from "../../actions/uiActions";
import { attendEvent, deleteAttendEvent } from "../../actions/eventActions";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import VideoPlayer from "../ui/VideoPlayer";
dayjs.extend(utc);

function createFileAndTriggerDownload(event) {
  let startDate = dayjs(`${event.eventStartDate}${event.eventStartTime}`, "YYYYMMDDHHmm").utc().format("YYYYMMDD[T]HHmm");
  let endDate = dayjs(`${event.eventEndDate}${event.eventEndTime}`, "YYYYMMDDHHmm").utc().format("YYYYMMDD[T]HHmm");

  let icsContent = "";
  icsContent += "BEGIN:VCALENDAR\r\n";
  icsContent += "VERSION:2.0\r\n";
  icsContent += "CALSCALE:GREGORIAN\r\n";
  icsContent += "PRODID:ekkoapp/ics\r\n";
  icsContent += "BEGIN:VEVENT\r\n";
  icsContent += `UID:${uuidv4()}\r\n`;
  icsContent += `SUMMARY:${event.title}\r\n`;
  icsContent += `DTSTART:${startDate}00Z\r\n`;
  icsContent += `DTEND:${endDate}00Z\r\n`;
  icsContent += `URL:${window.location.href}\r\n`;
  icsContent += `DESCRIPTION:${event.title}\r\n`;
  icsContent += "STATUS:CONFIRMED\r\n";
  icsContent += "END:VEVENT\r\n";
  icsContent += "END:VCALENDAR";

  let el = document.createElement("a");
  let fileName = "calendarfile.ics";
  // data in download file
  let file = new Blob([icsContent], { type: "text/calendar" });
  file.name = fileName; // the actual filename
  el.download = fileName; // the download-property on the a tag
  el.href = URL.createObjectURL(file); // Set href on a tag to the file
  el.style.display = "none"; // hides element before appending it to the dom
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
}

/**
 * Show the details of the event - here the user can attend the event of decline the event
 *
 * @param props
 * @param props.deleteAttendEvent({eventId}) -> deletes all connections to the event. This includes
 * answered questions and if the user is attending the event.
 * @param props.attendEvent({eventId, fields}) -> the fields are the answered questions
 * @returns {*}
 * @constructor
 */

const EventDetails = (props) => {
  // Event state from redux
  const { loading, selectedEvent: event, error } = useSelector((state) => state.event);

  const { user } = useSelector((state) => state.auth);

  // Language
  const { language: lang } = useSelector((state) => state.language);

  // Get the questions and main question for event
  const getEventQuestions = async () => {
    if (event.questions.length > 0) {
      props.showModalPage({
        title: " ",
        content: <EventFormBuilder />,
        useScrollView: false,
      });
    } else {
      props.attendEvent({
        eventId: event.id,
        fields: [{ ...event.mainQuestion, answer: { title: attendingType.YES } }],
        attendeeId: user.id,
      });
    }
  };

  // Load the contextMenu and adds elements based on if attending the event or no
  const onContextMenuClick = () => {
    let contextMenuItems = [];

    // Attend the event button
    if (
      event.isAttending === false &&
      ((event.seatsAvailable && event.numberOfAttendees < event.seatsAvailable) || !event.seatsAvailable)
    ) {
      contextMenuItems.push({
        icon: <CheckIcon />,
        title: lang.attendTheEvent,
        callback: () => getEventQuestions(),
      });
    }

    // Delete my answer for attending
    // Remove the attending
    contextMenuItems.push({
      icon: <TrashCanOutlineIcon />,
      title: lang.removeMyEventRegistration,
      callback: () => props.deleteAttendEvent({ eventId: event.id, attendeeId: user.id }),
    });

    // See the answers I've answered when attending
    event.isAttending === true &&
      event.questions.length > 0 &&
      // See answers to event questions
      contextMenuItems.push({
        icon: <ClipboardTextOutlineIcon />,
        title: lang.seeMyAnswers,
        callback: () =>
          props.showModalPage({
            title: " ",
            content: <EventAnswers />,
            useScrollView: false,
          }),
      });

    props.showContextMenu(contextMenuItems);
  };

  return (
    <div className={componentStyle()}>
      {loading && !error && <Spinner style={{ marginTop: "1rem" }} />}
      {!loading && error && <StatusBox />}
      {!loading && !error && event && (
        <div className="outer-component">
          <div className="event-description-container">
            {/* Event header with options button */}
            <div className="top-section-container">
              <p className="title">{event.title}</p>
              {event.isAttending !== null && (
                <ContextMenuButton
                  onClick={(e) => {
                    onContextMenuClick();
                    e.stopPropagation();
                  }}
                />
              )}
            </div>

            {/* The event date */}
            <div className="date-box">
              <CalendarBlankIcon />
              <p>
                {event.eventStartDate &&
                  event.eventStartTime &&
                  parseDateAndTime(event.eventStartDate, event.eventStartTime)}
                {event.eventEndDate &&
                  event.eventEndTime &&
                  ` - ${parseDateAndTime(event.eventEndDate, event.eventEndTime)}`}
              </p>
            </div>

            {/* Event deadline for answering */}
            {event.deadlineDate && event.isAttending === null && (
              <div className="date-box">
                <HourglassEmptyIcon />
                <p>{lang.attendBefore__placeholder__.replace("{{placeholder}}", parseDate(event.deadlineDate))} </p>
              </div>
            )}

            {/* Not attending icon and description */}
            {event.isAttending === false && (
              <div className="not-attending">
                <AccountOffIcon />
                <p>{lang.notAttending}</p>
              </div>
            )}

            {/* Attending icon and description */}
            {event.isAttending === true && (
              <div className="attending">
                <AccountCheckIcon />
                <p>{lang.youAreAttending}</p>
              </div>
            )}

            {/* Max number of attendees for the event (MAX = TRUE; REMAINING = FALSE;) (Default Logic) */}
            {(event.config.EVENT_DISPLAY_MAX_SEATS === true || event.config.EVENT_DISPLAY_MAX_SEATS === undefined) &&
              event.config.EVENT_DISPLAY_REMAINING_SEATS !== true &&
              event.seatsAvailable && (
                <div className="date-box">
                  <AccountMultipleIcon />
                  <p>{lang.max__placeholder__attendees.replace("{{placeholder}}", event.seatsAvailable)}</p>
                </div>
              )}

            {/* Max number of attendees for the event (MAX = TRUE; REMAINING = TRUE;) */}
            {(event.config.EVENT_DISPLAY_MAX_SEATS === true || event.config.EVENT_DISPLAY_MAX_SEATS === undefined) &&
              event.config.EVENT_DISPLAY_REMAINING_SEATS === true &&
              event.seatsAvailable && (
                <div className="date-box">
                  <AccountMultipleIcon />
                  <p>
                    {lang.eventMaxAndRemainingSeats
                      .replace("{{max}}", event.seatsAvailable)
                      .replace("{{remaining}}", event.seatsAvailable - event.numberOfAttendees)}
                  </p>
                </div>
              )}

            {/* Max number of attendees for the event (MAX = FALSE; REMAINING = TRUE;) */}
            {event.config.EVENT_DISPLAY_MAX_SEATS === false &&
              event.config.EVENT_DISPLAY_REMAINING_SEATS === true &&
              event.seatsAvailable && (
                <div className="date-box">
                  <AccountMultipleIcon />
                  <p>{lang.eventSeatsRemaining.replace("{{remaining}}", event.seatsAvailable - event.numberOfAttendees)}</p>
                </div>
              )}

            {/* Event location */}
            {event.location && (
              <div className="date-box location">
                <MapMarkerIcon />
                {/* Maps URI */}
                <a href={`maps:q=${event.location}`}>{event.location}</a>
                <OpenInNewIcon style={{ marginLeft: "0.2rem" }} />
              </div>
            )}

            {/* Add to calendar button */}
            {event.isAttending === true && (
              <ButtonRounded
                style={{ marginTop: "1.5rem" }}
                secondary={true}
                onClick={() => createFileAndTriggerDownload(event)}
              >
                <CalendarPlusIcon size="16" style={{ margin: "0 0.5rem 0 0" }} /> {lang.addToCalendar}
              </ButtonRounded>
            )}

            {/* Buttons */}
            {shouldShowEventButtons(event) && (
              <div style={{ marginTop: "1rem" }}>
                <Button onClick={() => getEventQuestions()} className="main-button">
                  {lang.iAmAttending}
                </Button>
                {!event.hideNotAttendButton && (
                  <Button
                    buttonType={"secondary"}
                    onClick={() =>
                      props.attendEvent({
                        eventId: event.id,
                        fields: [{ ...event.mainQuestion, answer: { title: attendingType.NO } }],
                        attendeeId: user.id,
                      })
                    }
                  >
                    {lang.iAmNotAttending}
                  </Button>
                )}
              </div>
            )}

            {/* No longer possible to attend/ not attend event due to endDate has been reached */}
            {!event.isAttending && event.attendingHasExpired && (
              <StatusBox
                className="event-maxed-out-container"
                icon={<AccountAlertIcon />}
                title={lang.attendingEventExpired}
                content={lang.attendingEventExpiredDescription}
              />
            )}

            {/* Max attendees reached -- data box! */}
            {!event.isAttending && event.seatsAvailable <= event.numberOfAttendees && !event.attendingHasExpired && (
              <StatusBox
                className="event-maxed-out-container"
                icon={<AccountAlertIcon />}
                title={lang.eventIsFilled}
                content={lang.maximumAttendeesHasBeenReached}
              />
            )}
          </div>
          <div className="event-description-container">
            {event?.video && (
              <VideoPlayer style={{ width: "100%" }} baseURL={event?.video?.baseURL} video={event?.video?.video} />
            )}
            {event.images && event.images.length > 0 && <ImageCarousel images={event.images} />}
            <div className="description" dangerouslySetInnerHTML={markDownParser(event.description || " ")} />
            {event.documents &&
              event.documents.map((document) => (
                <FileViewer
                  key={`file-viewer-compontent${document.file}`}
                  href={`${document.baseURL || ""}${document.file}`}
                  title={document.title}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;

  div.outer-component {
    width: 100%;
    margin: 0 auto;
    max-width: ${breakpoints.md}px;
    background-color: var(--white);

    @media screen and (min-width: ${breakpoints.md}px) {
      border-radius: 3px;
      overflow: hidden;
      border: 1px solid var(--midGrey);
    }
    @media screen and (max-width: ${breakpoints.md - 1}px) {
      border-top: 1px solid var(--midGrey);
      border-bottom: 1px solid var(--midGrey);
    }
  }

  div.event-description-container:first-of-type {
    border-bottom: 1px solid var(--midGrey);

    @media screen and (min-width: ${breakpoints.md}px) {
      border: none;
    }
  }

  div.event-description-container {
    width: 100%;
    background-color: var(--white);
    padding: 1rem;

    div.description {
      white-space: normal;

      ol,
      ul {
        margin-left: 1.2rem;
      }

      p,
      ul,
      li {
        margin-bottom: 0.5rem;
      }
    }

    div.top-section-container {
      display: flex;
      justify-content: space-between;

      svg {
        height: 1.6rem;
        width: auto;
        padding: 0;
      }
    }

    button.main-button {
      margin-bottom: 0.5rem;
    }

    p.title {
      font-weight: bold;
      font-size: 1.15rem;
      margin-bottom: 0.5rem;
      white-space: normal;
    }

    div.date-box,
    div.not-attending,
    div.attending,
    div.location {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      p {
        font-size: 0.9rem;
        color: ${colors.midDarkGrey};
      }

      svg {
        height: 1rem;
        width: auto;
        fill: ${colors.midDarkGrey};
        margin-right: 0.25rem;
      }
    }

    div.not-attending {
      margin-bottom: 0;

      p {
        color: var(--red);
        font-weight: bold;
      }

      svg {
        fill: var(--red);
      }
    }

    div.attending {
      p {
        color: var(--greenBright);
        font-weight: bold;
      }

      svg {
        fill: var(--greenBright);
      }
    }

    div.event-maxed-out-container {
      h2 {
        font-size: 1rem;
      }

      p {
        font-size: 0.85rem;
      }
    }

    div.location {
      a {
        color: ${colors.midDarkGrey};
        font-size: 0.9rem;

        &:hover,
        &:active {
          text-decoration: underline;
          color: var(--black);
        }
      }
    }
  }

  div.event-description-container:last-of-type {
    margin: 0;
    border-top: none;
    flex: 1;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  deleteAttendEvent: bindActionCreators(deleteAttendEvent, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  attendEvent: bindActionCreators(attendEvent, dispatch),
  showContextMenu: bindActionCreators(showContextMenu, dispatch),
});

export default connect(null, mapDispatchToProps)(EventDetails);
