// Libs
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";

// Components
import PageBuilderTreeLeaf from "./PageBuilderTreeLeaf";
import ButtonRounded from "../../../../ui/ButtonRounded";

// Styles
import colors from "../../../../../style/colors";

// Config
import breakpoints from "../../../../../config/breakpoints";

// Utilities
import removePageFromPage from "../../../utilities/removePageFromPages";

// Actions
import { pagesChangeHandler } from "../../../../../actions/pageBuilderActions";
import { hideModalPage } from "../../../../../actions/uiActions";

const PageBuilderTree = (props) => {
  const dispatch = useDispatch();
  const { viewOnly = false, pageToBeMoved = undefined, pageClickCallback = undefined } = props;

  const lang = useSelector((state) => state.language.language);
  const pages = useSelector((state) => state.pageBuilder.pages);

  const placeAtTopLevel = () => {
    let newPages = removePageFromPage({ pageToBeRemovedId: pageToBeMoved.id, pages });

    pageToBeMoved.parentId = 0;
    pageToBeMoved.sortOrder = newPages[newPages.length - 1].sortOrder + 1;
    pageToBeMoved.flags.modified = true;
    newPages.splice(newPages.length, 0, pageToBeMoved);

    dispatch(pagesChangeHandler({ pages: newPages }));
    dispatch(hideModalPage());
  };

  return (
    <div data-test-id="page-builder-tree-structure" className={componentStyle({ viewOnly })}>
      {!viewOnly && pageToBeMoved && pageToBeMoved.parentId !== 0 && pageToBeMoved.parentId && (
        <>
          <div className="page-builder-wrapper">
            <ButtonRounded onClick={placeAtTopLevel} className="top-level-btn">
              {lang.placeAsAtTopLevelPage}
            </ButtonRounded>
          </div>
          <div className="divider" />
        </>
      )}
      <div className="page-builder-wrapper">
        <div className="leaf-container">
          {pages.map((page) => (
            <PageBuilderTreeLeaf
              key={page.id}
              viewOnly={viewOnly}
              page={page}
              pageToBeMoved={pageToBeMoved}
              pageClickCallback={pageClickCallback}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const componentStyle = ({ viewOnly }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);
  padding: ${!viewOnly ? "0.5rem" : ""};
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .page-builder-wrapper {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: ${breakpoints.md}px;

    .top-level-btn {
      margin: 1rem 0.5rem;
    }

    .leaf-container {
      margin: 0.5rem 0;
      padding: 0 0.25rem;
    }
  }

  & > .divider {
    width: 100%;
    border: 1px solid var(--midGrey);
  }
`;

export default PageBuilderTree;
