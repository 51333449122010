import colors from "../../../../style/colors";
import { useDispatch, useSelector } from "react-redux";
import ListItem from "../../ListItem";
import { AlertOutlineIcon, CheckBoxIcon, CheckBoxOutlineBlankIcon, InformationOutlineIcon } from "mdi-react";
import FloatingSearchInput from "../../FloatingSearchInput";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import highlightMatch from "../../../../utilities/highlight-match";
import ButtonRounded from "../../ButtonRounded";
import useAuthorModal from "../../../../hooks/useAuthorModal";
import useLang from "../../../../hooks/useLang";
import ActionWrapper from "../../ActionWrapper";
import showGroupMembers from "../utilities/showGroupMembers";
import getUserSubTitle from "../utilities/getUserSubTitle";
import { groupTypes } from "../config/groupTypes";
import getGroupTypeName from "../utilities/getGroupTypeName";
import StatusBox from "../../StatusBox";

function SelectionPanelTab({
  groupType,
  addAllFunction,
  addOneFunction,
  removeOneFunction,
  idField = "id",
  filterFunction,
  countField = "membersCount",
  titleField = "title",
}) {
  // Hooks
  const dispatch = useDispatch();
  const authorModal = useAuthorModal();
  const lang = useLang();

  // Data
  const data = useSelector((s) => s.targetGroupModal[groupType].data);
  const targetGroupData = useSelector((s) => s.targetGroupModal.targetGroup[groupType]);

  // Search and filtering
  const [searchterm, setSearchterm] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [filterData] = useDebouncedCallback(() => {
    let regex = new RegExp(searchterm, "gi");
    setFilteredData(() => filterFunction({ regex, data }));
  }, 250);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    filterData();
  }, [searchterm]);

  return (
    <div className="list-items-container">
      <div className="actions-wrapper">
        <ButtonRounded onClick={() => dispatch(addAllFunction(filteredData.map((d) => d[idField])))} secondary={true}>
          {lang.choose} {lang.all.toLowerCase()}
        </ButtonRounded>

        <p className="meta" style={{ color: "var(--darkGrey)" }}>
          {lang.shows} {filteredData.length} {getGroupTypeName({ count: filteredData.length, lang, groupType })}
        </p>
      </div>

      <FloatingSearchInput
        className="search-input"
        value={searchterm}
        onChange={(e) => setSearchterm(e.target.value)}
        onClearSearch={() => setSearchterm("")}
      />

      {filteredData.length === 0 && searchterm && (
        <StatusBox icon={<InformationOutlineIcon />} title={lang.noResults} content={lang.tryABroaderSearch} />
      )}

      {filteredData.map((dataEntry) => {
        const isSelected = targetGroupData.some((tgId) => tgId === dataEntry[idField]);

        return (
          <ListItem
            key={`selection_${groupType}_${dataEntry[idField]}`}
            dataTestId={`selection-panel__${groupType}__${dataEntry[idField]}`}
            style={{ border: `1px ${colors.midGrey} solid` }}
            title={highlightMatch(`${dataEntry[titleField]}`, searchterm)}
            clickable={true}
            onClick={() => dispatch(isSelected ? removeOneFunction(dataEntry[idField]) : addOneFunction(dataEntry[idField]))}
            iconLeft={
              isSelected ? (
                <CheckBoxIcon className="checkbox-icon checked" data-test-id="selected-icon" />
              ) : (
                <CheckBoxOutlineBlankIcon className="checkbox-icon unchecked" data-test-id="unselected-icon" />
              )
            }
            subTitle={
              groupType === groupTypes.users ? (
                // Users has an advanved subTitle highlighting to show if their usergroup, masterGroup or jobtitle
                // is part of the targetGroup
                getUserSubTitle({ user: dataEntry, searchterm })
              ) : (
                // The rest of the groups has a much simpler subTitle being a count of members. Clicking
                // the amount of members opens a modal with the users
                <ActionWrapper
                  className={"show-group-members-button"}
                  onClick={(e) =>
                    showGroupMembers({
                      type: groupType,
                      id: dataEntry[idField],
                      title: dataEntry.title,
                      clickEvent: e,
                      authorModal,
                    })
                  }
                >
                  {dataEntry[countField]} {lang.members}
                </ActionWrapper>
              )
            }
          />
        );
      })}
    </div>
  );
}

export default SelectionPanelTab;
