import { css } from "emotion";

export const componentStyle = (isCreateMode = false) => css`
  padding: 0.65rem 0.65rem 1.5rem;

  ${!isCreateMode
    ? css`
        border: 1px solid var(--midDarkGrey);
      `
    : ""}

  .action-btn {
    margin: 0.5rem auto 0;
    width: 100%;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;

    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  .line {
    border-top: 1px solid var(--midGrey);
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .field-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;

    .information-box {
      margin: 0.5rem 0;
      width: 100%;
    }

    .module-type-selector {
      width: 100%;
    }

    &:first-of-type {
      margin-top: 0;
    }

    p.title {
      margin-right: 0.75rem;
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 0.25rem;
    }

    .show-at-front-page-group {
      width: 100%;
      display: flex;
      justify-content: space-evenly;

      label {
        width: 100%;

        .radio {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.5rem 0.85rem;
          width: 100%;

          svg {
            height: 1.25rem;
            width: 1.25rem;
          }

          p {
            font-size: 0.9rem;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }

    .field-wrapper {
      width: 100%;
      margin-top: 0.5rem;

      &:first-of-type {
        margin-top: 0;
      }

      label {
        font-size: 0.9rem;
        color: var(--darkGrey);
      }
    }
  }

  .hint {
    font-size: 0.9rem;
    color: var(--darkGrey);
  }
`;
