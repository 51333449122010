import React from "react";
import { css } from "emotion";
import { ChevronRightIcon } from "mdi-react";
import pageNavigatorV2 from "../../../utilities/page-navigator-v2";
import linkPathCreator from "../../../utilities/get-link-path-from-page-object";
import pageTransitions from "../../../config/page-transitions";
import SearchPageBreadcrumb from "./SeachPageBreadcrumb";

const SearchResultPage = (props) => {
  const page = props.result.page;

  return (
    <div
      key={`page__${page.id}`}
      className={`list-element ${componentStyles()}`}
      onClick={() => pageNavigatorV2({ path: `${linkPathCreator(page, "absolute")}`, direction: pageTransitions.forward })}
    >
      <SearchPageBreadcrumb page={page} isLarge={true} key={`search_breadcrumb__page__${page.id}`} />

      <ChevronRightIcon className="navigation-arrow-indicator" />
    </div>
  );
};

const componentStyles = (props) => css`
  display: flex;
  align-items: center;
`;

export default SearchResultPage;
