// Libs
import React, { useEffect, useRef, useState } from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";

// Components
import PageListItem from "./pageListItem/PageListItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { GroupedPageListItemSkeleton } from "./pageListItem/ui/PageListItemSkeleton";

// Styles
import breakpoints from "../../../config/breakpoints";

// Actions
import { pagesChangeHandler, updateParentPageId } from "../../../actions/pageBuilderActions";

// Utilities
import changePageSortOrder from "../utilities/changePageSortOrder";
import PagePath from "./PagePath";

// Hooks
import filterPages from "../utilities/filterPages";
import { lock, unlock } from "tua-body-scroll-lock";
import InformationBox from "../../ui/InformationBox";

// Context
import { PageBuilderContext } from "../context/PageBuilderContext";

const PageBuilderContent = (props) => {
  const dispatch = useDispatch();
  const containerRef = useRef();

  const lang = useSelector((state) => state.language.language);
  const { pages: reduxPages, currentParentPageId, loadingPages: loading } = useSelector((state) => state.pageBuilder);
  const filters = useSelector((state) => state.pageBuilderFilter.filters);

  const [pages, setPages] = useState([]);

  useEffect(() => {
    setPages(filterPages({ pages: props.pages, filters }));
  }, [props.pages, filters]);

  useEffect(() => {
    lock(containerRef.current);
    return () => {
      unlock(containerRef.current);
    };
  }, []);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    let fromPageId = pages[source.index]?.id;
    let toPageId = pages[destination.index]?.id;

    let sortedAllPages = changePageSortOrder({
      fromPageId,
      toPageId,
      pages: reduxPages,
      currentParentPageId: currentParentPageId ? parseInt(currentParentPageId, 10) : undefined,
    });

    let sortedPages = changePageSortOrder({
      fromPageId,
      toPageId,
      pages: props.pages,
      currentParentPageId: currentParentPageId ? parseInt(currentParentPageId, 10) : undefined,
    });

    setPages(filterPages({ pages: sortedPages, filters }));

    dispatch(pagesChangeHandler({ pages: sortedAllPages }));

    lock(containerRef.current);
  };

  const onDragStart = () => {
    unlock(containerRef.current);
  };

  return (
    <PageBuilderContext.Consumer>
      {({ disableDraggableAction }) => (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <div
            ref={containerRef}
            className={`${componentStyle({ hasPagePath: Boolean(currentParentPageId) })} ${
              props.className ? props.className : ""
            }`}
          >
            {disableDraggableAction && (
              <div className="content-wrapper">
                <InformationBox className="information-box" title={"Opmærksom!"}>
                  {lang.pagesPositionNotChangeableBecauseParentIsFixed}
                </InformationBox>
              </div>
            )}

            {currentParentPageId && (
              <PagePath
                currentPageId={currentParentPageId ? parseInt(currentParentPageId, 10) : undefined}
                className="paths"
              />
            )}

            <Droppable droppableId={"column-1"}>
              {(provided) => (
                <div className="content-wrapper" ref={provided.innerRef} {...provided.droppableProps}>
                  {loading && <GroupedPageListItemSkeleton />}
                  {pages.map((page, index) => (
                    <PageListItem {...props} className="page-item" key={page.id} page={page} index={index} />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      )}
    </PageBuilderContext.Consumer>
  );
};

const componentStyle = ({ hasPagePath = false }) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: ${hasPagePath ? "" : "1.25rem 0"};
  padding-bottom: 1rem;

  .information-box {
    margin-top: 1rem;
    background-color: white;
  }

  .content-wrapper {
    width: 100%;
    max-width: ${breakpoints.lg}px;
  }

  .paths {
    margin: 0.35rem 0;
    padding: 0.5rem 0 0.5rem 1rem;
  }
`;

export default PageBuilderContent;
