import { css } from "emotion";
import { useSelector } from "react-redux";
import colors from "../../../style/colors";
import { registrationTypes as QUESTION_TYPES } from "../../registration/config";

/* Get JSX-representation of a question */
function getAnswerFromQuestion(question) {

  switch (question.type) {
    case QUESTION_TYPES.DROPDOWN_WITH_EXTERNAL_DATA:
      return <p className="value">{question?.answer?.value?.title}</p>;

    case QUESTION_TYPES.TEXT:
    case QUESTION_TYPES.TIME:
      return <p className="value">{question?.answer?.title}</p>;

    case QUESTION_TYPES.DROPDOWN_FORM_BUILDER:
      return question?.answer?.title?.map((answer) => (
        <div
          key={`question-data-form-builder-${question.id}-${answer.id} `}
          className={`extras-wrapper ${answer.hasChanged ? "has-changed" : ""}`}
        >
          <div className="title-wrapper">
            <p>{answer.title}</p>
            {answer.isShared && <p className="shared">Delt tillæg</p>}
          </div>
          <p>{answer.amount}</p>
          <p>{answer.comment}</p>
        </div>
      ));

    default:
      return null;
  }
}

/* Get a diffable string-representation of the last question (I suspect doing JSON.stringify or hashing the entire object
  would result in to many false positive if the form or question changed between checks) */
function getDiffableQuestionAnswerValue(question) {
  switch (question.type) {
    case QUESTION_TYPES.DROPDOWN_WITH_EXTERNAL_DATA:
      return question?.answer?.value?.title;

    case QUESTION_TYPES.TEXT:
    case QUESTION_TYPES.TIME:
      return question?.answer?.title;

    default:
      return null;
  }
}

/* For extras getting a diffable string is a bit more complicated. I am creating a string representing the data we care about.
   The string is stored temporarily on the extra itself as `__diffableString`
*/
function getDiffableStringFromExtra(extra) {
  const { externalOptionId, amount = "", comment = "", isShared } = extra;

  return {
    ...extra,
    __diffableString: `${externalOptionId}__${amount}__${comment}__${isShared ? "isShared" : ""}`,
  };
}

/* Iterate over questions and flag those with changes with a `hasChanges` key */
const flagQuestionsWithChanges = (logEntry, previousLogEntry) => {
  // The first entry should not be diffed as it is technically 100% different from the non-existing previous record
  if (!previousLogEntry) return logEntry;

  return logEntry.map((question) => {
    // Setting hasChanged to false as default
    let hasChanged = false;

    // This question as it was in the previous history-log-entry
    let previousQuestion = previousLogEntry?.find((q) => q.id === question.id);

    // Get a string representation of the current question as it is now and as it was just before
    let previousValue = getDiffableQuestionAnswerValue(previousQuestion);
    let currentValue = getDiffableQuestionAnswerValue(question);

    // For extras the check is a bit more complicated as we have to check each extra individually
    if (question.type === QUESTION_TYPES.DROPDOWN_FORM_BUILDER && question?.answer?.title) {
      // Get an array of string-representations of all extras for previous entry. This array makes
      // it easy to check if any given extra was present in the previous history log entry
      let previousExtras = previousQuestion?.answer?.title?.map((e) => getDiffableStringFromExtra(e).__diffableString);
      let currentExtras = question?.answer?.title?.map(getDiffableStringFromExtra);

      // Iterate over the extras (which apparently are stored in an array inn question.answer.title)
      question.answer.title = currentExtras.map((extra) => {
        let extraHasChanged = false;
        // If the current extra wasn't present in the previous log entry then it has changed (or been created)
        if (!previousExtras.includes(extra.__diffableString)) {
          extraHasChanged = true;
        }
        extra.hasChanged = extraHasChanged;
        return extra;
      });

      // This is for the questions that arent extras
    } else if (currentValue != previousValue) {
      hasChanged = true;
    }

    return {
      ...question,
      hasChanged,
    };
  });
};

const HistoryLogEntry = ({ logEntryData, previousLogEntryData }) => {
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  return (
    <div className={componentStyle(primaryColor)}>
      {flagQuestionsWithChanges(logEntryData, previousLogEntryData).map((question) => (
        <div className={`answer-wrapper ${question.hasChanged ? "has-changed" : ""}`} key={question.id}>
          <p className="title">{question.title}:&nbsp;</p>
          {getAnswerFromQuestion(question)}
        </div>
      ))}
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  .extras-wrapper {
    border: 1px solid ${colors.midGrey};
    border-radius: 3px;
    padding: 0.5rem;
    margin: 0.5rem 0;

    &:last-child {
      margin-bottom: 0;
    }

    div.title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.25rem;

      p {
        color: ${colors.midDarkGrey};
        margin: 0;

        &.shared {
          font-size: 0.85rem;
          color: ${primaryColor};
          border: 1px solid ${colors.midGrey};
          padding: 0.125rem 0.25rem;
          border-radius: 3px;
        }
      }
    }
  }
`;

export default HistoryLogEntry;
